(function() {
        function RouterService($http,$q) {

            var states = [];

            var searchRoute = function(toState) {
                for (var index = 0; index < states.length; index++) {
                    if (states[index].state == toState) {
                        return true;
                    }
                }
                return false;
            }

            var Router = {
                isAllowed(toState) {
                    var deffered = $q.defer()

                    if (states.length == 0) {
                        $http.get('api/routes').then(response => {
                            states = response.data;
                        }).then(function() {
                          deffered.resolve(searchRoute(toState));
                        });
                    } else {
                      deffered.resolve(searchRoute(toState))
                    }
                    return deffered.promise;
                },


                isLinkActive(element) {
                    var deffered = $q.defer();

                    $http.get('api/routes/' + element).then(response => {
                        let links = response.data;
                        deffered.resolve(links);
                    });

                    return deffered.promise;
                },

                getAlternativeTemplate(state) {
                  var deffered = $q.defer();

                  $http.get('api/routes/template/' + state).then(response => {
                    let template = response.data;
                    deffered.resolve(template);
                  });

                  return deffered.promise;
                }

    };

    return Router;
}

angular.module('mvpcarrefourApp')
    .factory('Router', RouterService);

})()
